import React from 'react';
import sprite from '../img/sprite.svg';

const Sprite = ({ id }) => (
  <svg className={id}>
    <use href={`${sprite}#${id}`} ></use>
    {/* <use xlinkHref={`${sprite}#${id}`}></use> */}
  </svg>
);

export default Sprite;
