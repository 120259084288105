import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

/* That's global State storage */
import selectContext from './components/SelectContext';
// And then for use add in component: import SelectContext from './SelectContext';
// And then for use add in component: const { value } = useContext(SelectContext);

/* That's default "Alert" component */
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* Use for show information: toast.info("text"), toast.warn("text"), toast.error("text"), toast.success("text"); */
/* Use:
  <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
  /> 
*/

/* That's default "Loader spinner" component */
import { Audio } from 'react-loader-spinner';
/* Use: {loading && <Audio className="loader" height="80" width="80" radius="9" color="green" ariaLabel="loading" />} */

/* That's styles */
import './sass/main.css';
import Sprite from './components/Sprite';

/* That's Components */
import RevolveMap from "./components/RevolverMap";
// import ThemeModule from './components/theme_module_v.1.4.jsx'
// import Contracts from './components/Contracts';
// import About from './components/About';
// import Error from './components/Error';
// import Provisions from './components/Provisions';
import Modal from "./components/Modal";
// import Music from './components/Music';

// import Theme from './components/Theme';
// <Theme />

// Используем lazy для загрузки компонентов
const About = lazy(() => import('./components/About'));
const Contracts = lazy(() => import('./components/Contracts'));
const Provisions = lazy(() => import('./components/Provisions'));
const Error = lazy(() => import('./components/Error'));
const Music = lazy(() => import('./components/Music'));



const Applicatiion = () => {

  const version = "1.0";
  
    // Цветовые схемы
  const classData = [
    // { backgroundColor: '#ffffff', color: '#000000', fill: '#000000' }, // Светлая стандартная тема
    // { backgroundColor: '#000000', color: '#ffffff', fill: '#ffffff' },  // Темная стандартная тема
    { backgroundColor: '#b39551', color: '#252525', fill: '#252525', borderColor: '#252525' }, // Светлая тема
    { backgroundColor: '#252525', color: '#b39551', fill: '#b39551', borderColor: '#b39551' },  // Темная тема
  ];

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);  // Отслеживаем загрузку
  const [select, setSelect] = useState(null);
  const [language, setLanguage] = useState("EN");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [currentIndexStyle, setCurrentIndexStyle] = useState(() => {
    const savedIndex = localStorage.getItem('choiceStyle');
    return savedIndex !== null ? JSON.parse(savedIndex) : 0;
  });

  
    /* That's link on bacic page */  
  const location = useLocation();
  // console.log(location);

    /* That's global State storage */
  const [storage, setStorage] = useState({
    data: data,
    setData: setData,
    loading: loading,
    setLoading: setLoading,
    select: select,
    setSelect: setSelect,
    language: language,
    isVideoPlaying: isVideoPlaying,
    setIsVideoPlaying: setIsVideoPlaying,
  });

  useEffect(() => {
    setStorage(prevStorage => ({
      ...prevStorage,
      language,
      loading,
      data,
      select,
    }));
  }, [language, loading, data, select]);






// ------------------- THEME ---------------------

useEffect(() => {
  const indexTheme = JSON.parse(localStorage.getItem('choiceStyle')) || 0;
  setChangeTheme(indexTheme);
}, []);

useEffect(() => {
  const savedTheme = JSON.stringify(currentIndexStyle);
  if (savedTheme !== localStorage.getItem('choiceStyle')) {
    localStorage.setItem('choiceStyle', savedTheme);
  }
}, [currentIndexStyle]);

const handleThemeChange = () => {
  const nextIndex = (currentIndexStyle + 1) % classData.length;
  setCurrentIndexStyle(nextIndex);
  setChangeTheme(nextIndex);
};

const setChangeTheme = (index) => {
  const theme = classData[index];

  document.body.style.backgroundColor = theme.backgroundColor;
  document.body.style.color = theme.color;
  document.body.style.borderColor = theme.borderColor;
  document.body.style.fill = theme.fill;

  const header = document.querySelector(".header");
  if (header) {
    header.style.backgroundColor = theme.backgroundColor;
    header.style.color = theme.color;
    header.style.borderColor = theme.borderColor;
    header.style.fill = theme.fill;
  }
};



  // -------------------- LANGUAGE ---------------------

useEffect(() => {
if ("language" in navigator || "userLanguage" in navigator) {
    let lang = navigator.language || navigator.userLanguage;
    console.log(`Language on client system: ${lang}`);
    
    if (lang.includes("RU") || lang.includes("ru")) {
      setLanguage("RU");
      // console.log(`Automatic set language on page: Russian (${language})`);
      console.log(`Automatic set language on page: Russian (RU)`);
    } else if (lang.includes("en") || lang.includes("us") || lang.includes("EN") || lang.includes("US") || lang.includes("CA")) {
      setLanguage("EN");
      // console.log(`Automatic set language on page: English (${language})`);
      console.log(`Automatic set language on page: English (EN)`);
    } else if (lang === null || lang === undefined) {
      setLanguage("EN");
      // console.log(`Language on client system is not detected... Automatic set language on page: English (${language})`);
      console.log(`Language on client system is not detected... Automatic set language on page: English (EN)`);
    } else {
      setLanguage("EN");
      // console.log(`Language on client system is unknow... Automatic set language on page: English (${language})`);
      console.log(`Language on client system is unknown... Automatic set language on page: English (EN)`);
    }
};
}, []);


const [text, setText] = useState({
  news: "News",
  signed: "Signed contracts",
  provisions: "Provisions of the GFWC",
  about: "About company",
  contact: "Contact information:",
  telephone: "Telephone:",
  mail: "E-mail:",
  facebook: "Facebook:",
  creator: `Website (version: ${version}) made by Anatolii Ovcharuk - younger (Link on Github).`,
  theme: "Change theme",
  lang_inf: "You are set language: English (EN)",
  wa: "Send message on ",
  loading: "Loading...",
  rights: "Copyrignt © Anatoliy Ovcharuk, All rights reserved.",
});

useEffect(() => {
  if (language === "EN") {
    setText({
      news: "News",
      signed: "Signed contracts",
      provisions: "Provisions of the GFWC",
      about: "About company",
      contact: "Contact information:",
      telephone: "Telephone:",
      telegram: "Telegram:",
      mail: "E-mail:",
      facebook: "Facebook:",
      creator: `Website (version: ${version}) made by Anatolii Ovcharuk - younger (Link on Github).`,
      theme: "Change theme",
      lang_inf: `Set language: English (${language})`,
      wa: "Send message on ",
      loading: "Loading...",
      rights: "Copyrignt © Anatoliy Ovcharuk, All rights reserved.",
    });
  } else if (language === "RU") {
    setText({
      news: "Новости",
      signed: "Подписанные контракты",
      provisions: "Положение о ЗФЗМ",
      about: "О компании",
      contact: "Контактная информация:",
      telephone: "Номер телефона:",
      telegram: "Телеграм:",
      mail: "Электронная почта:",
      facebook: "Фейсбук:",
      creator: `Вебсайт (версия: ${version}) создал Анатолий Овчарук - младший (Ссылка на Гитхаб).`,
      theme: "Сменить тему",
      lang_inf: `Выбран язык: Русский (${language})`,
      wa: "Отправить сообщение на ",
      loading: "Загрузка...",
      rights: "Авторское право © Анатолий Овчарук, все права защищены.",
    });
  } else {
    setText({
      news: "News",
      signed: "Signed contracts",
      provisions: "Provisions of the GFWC",
      about: "About company",
      contact: "Contact information:",
      telephone: "Telephone:",
      telegram: "Telegram:",
      mail: "E-mail:",
      facebook: "Facebook:",
      creator: `Website (version: ${version}) made by Anatolii Ovcharuk - younger (Link on Github).`,
      theme: "Change theme",
      lang_inf: `Set language: English (${language})`,
      wa: "Send message on ",
      loading: "Loading...",
      rights: "Copyrignt © Anatoliy Ovcharuk, All rights reserved.",
    });
  }
}, [language]);

// const [isFirstRender, setIsFirstRender] = useState(true);
const [renderCount, setRenderCount] = useState(0);
useEffect(() => {
  // if (!isFirstRender) {
  //   toast.info(`${text.lang_inf}`);
  //   console.log(`${text.lang_inf}`);
  // } else {
  //   setIsFirstRender(false);
  // }

  if (renderCount >= 3) {  // Появление сообщения на третьем рендере (индекс 2)
    toast.info(`${text.lang_inf}`);
    console.log(`${text.lang_inf}`);
  }

  // Увеличиваем счетчик рендеров после каждого рендера
  setRenderCount(prevCount => prevCount + 1);
}, [text]);

const lang = (event) => {
  setLanguage(event.target.value);
};


  

// -------------------- MODAL ---------------------
  
// useEffect(() => {
//   console.log("select", select);
// }, [select]);

  

  
  
// -------------------- LOADER ---------------------  

    // Загрузчик
  const loader = <div className='loader'>
    <Audio className='loader' height="80" width="80" radius="9" color="inherit" ariaLabel="loading" />
    {/* <p>{text.loading}</p> */}
  </div>;

  
  
  
  
  // -------------------- Разметка ---------------------  
  
  const parts =
    <Suspense fallback={loader}>
      <Routes>
        {/* <Route path="/"> */}
        <Route path="/" index element={<About />} />
        <Route path="contracts" element={<Contracts />} />
        <Route path="provisions" element={<Provisions />} />
        {/* <Route path="about" element={<About />} /> */}
        {/* </Route> */}
        <Route path="*" element={<Error />}></Route>
      </Routes>
    </Suspense>;

  
  
  
  
  return (
    <>
    <selectContext.Provider value={{storage}}>
      <header className="header">
        <Sprite id="logo" />
        <nav>
            <ul>
              <li><NavLink className='mark' to="/">{text.about}</NavLink></li>
              <li><NavLink className='mark' to="contracts" state={{ from: location }}>{text.signed}</NavLink></li>
              <li><NavLink className='mark' to="provisions" state={{ from: location }}>{text.provisions}</NavLink></li>
              {/* <li><NavLink className='mark' to="about" state={{ from: location }}>{text.about}</NavLink></li> */}
            </ul>
        </nav>
        <section className='settings'>
            <button onClick={handleThemeChange} aria-label="change theme on page"><Sprite id="theme" />{text.theme}</button>
            <select id='language' name="select" value={language} onChange={(event) => lang(event)}>
              <option value="EN" defaultValue>English (EN)</option>
              <option value="RU">Русский (RU)</option>
            </select>
            <Music isVideoPlaying={isVideoPlaying} />
        </section>
      </header>
      
      <main className="main">
          {loading ? loader || <p className='loader_text'>{text.loading}</p> : parts}
      </main>
      
      <footer className="footer">
          <h3>{text.contact}</h3>
        <address>
          <a className="contact" href="tel:+79902896767" target='_blank'><Sprite id="phone" /><p>{text.telephone} +7 (990) 289-67-67</p></a>
          <a className="contact" href="https://telegram.me/anatoliyovcharuk" target='_blank'><Sprite id="telegram" /><p>{text.telegram} @anatoliyovcharuk</p></a>
          <a className="contact" href="https://wa.me/79902896767" target='_blank'><Sprite id="whatsapp" /><p>{text.wa}WhatsApp</p></a>
          <a className="contact" href="mailto:anatoliyovcharuk@gmail.com" target='_blank'><Sprite id="mail" /><p>{text.mail} anatoliyovcharuk@gmail.com</p></a>
          <a className="contact" href="https://www.facebook.com/The-Golden-Fund-of-the-Worlds-Celebrities-105944930989343/" target='_blank'><Sprite id="facebook" /><p>{text.facebook} www.facebook.com/The-Golden-Fund-of-the-Worlds-Celebrities-105944930989343</p></a>
        </address>
        <RevolveMap />
          <p className='rights'>{text.rights}</p>
          <a className='creator' href='https://github.com/Anatolii-Ovcharuk' target='_blank'>{text.creator}</a>
      </footer>
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light" />   
    </selectContext.Provider>
    <Modal content={select} />
    </>
  );
}

export default Applicatiion;

